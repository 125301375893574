import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { detectBrowserlanguage } from './services/language';
import Routers from './routes';
import LinkedInTag from 'react-linkedin-insight';
import messages_nl from "./translations/nl.json";
import messages_en from "./translations/en.json";
import messages_de from "./translations/de.json";

const App = () => {

    const [LanguageObject, setLanguageObject] = useState({});
    const [currentLanguage, setCurrentLanguage] = useState(detectBrowserlanguage('en') || 'en');
    const [loading, setLoading] = useState(true);
    const cookie_settings = JSON.parse(localStorage.getItem("cookie_settings") || "{}");
    const cookie_consens = localStorage.getItem("cookie_consens") || false;

    if (cookie_consens && cookie_settings.allow_marketing) {
        LinkedInTag.init(5454058, undefined, false);
    }

    useEffect(() => {
        setLanguageObject({
            messages: {
                en: messages_en,
                nl: messages_nl,
                de: messages_de,
            },
        }, setLoading(false));
    }, []);


    return (
        <React.Fragment>
            {loading ? (<p>loading language settings</p>) : (
                <IntlProvider locale={currentLanguage} messages={LanguageObject.messages[currentLanguage]}>
                    <Routers locale={currentLanguage} setCurrentLanguage={setCurrentLanguage}></Routers>
                </IntlProvider>
            )}
        </React.Fragment>
    );
}

export default App;