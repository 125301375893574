import React, { useState, } from "react";
import ContactModal from '../../../components/contact/contact.modal';
import { PopupButton, } from "react-calendly";

import Background from '../../../assets/images/demo_view.png';
import { FormattedMessage, } from "react-intl";
import { CheckCircle, } from "@phosphor-icons/react";

const HomePageHero = ({
    history,
    ...props
}) => {

    const [openContactModal, setOpenContactModal] = useState(false);
    const { GAevent, } = props;


    return (

        <section className="banner__section sec__pad">
            <div className="auto_container">
                <div className="row">
                    <div className="col-xl-6 col-lg-12" >
                        <div className="banner__content__left">

                            <div className="banner__title">
                                <h1><FormattedMessage id="home-hero-tagline" /></h1>
                            </div>

                            <div className="banner__text">
                                <p style={{ fontWeight: "700", fontSize: "20px", paddingBottom: "25px" }}>
                                    <strong>Rental made easy!</strong>
                                </p>
                                <p><FormattedMessage id="homepage-hero-txt" /></p>
                            </div>

                            <ul className="home-application-list">
                                <li><CheckCircle size={24} weight="bold" color="orange" /> <FormattedMessage id="homepage-hero-list-a" /></li>
                                <li><CheckCircle size={24} weight="bold" color="orange" /> <FormattedMessage id="homepage-hero-list-b" /></li>
                                <li><CheckCircle size={24} weight="bold" color="orange" /> <FormattedMessage id="homepage-hero-list-c" /></li>
                                <li><CheckCircle size={24} weight="bold" color="orange" /> <FormattedMessage id="homepage-hero-list-d" /></li>
                                <li><CheckCircle size={24} weight="bold" color="orange" /> <FormattedMessage id="homepage-hero-list-e" /></li>
                            </ul>

                            <div className="text-center py-4">
                                <div className="form-group">
                                    <button className="btn-one my-2" onClick={e => { setOpenContactModal(true); GAevent('CTA', "Contact Modal"); }}>
                                        <span className="txt"><FormattedMessage id="ask-question" /></span>
                                    </button>

                                    {/* Planner for the calendly schedular */}
                                    <div onClick={e => { GAevent('CTA', 'request_demo') }}>
                                        <PopupButton
                                            className="btn-one ms-2 ms-md-4 ms-xl-0 my-2"
                                            rootElement={document.getElementById("root")}
                                            url="https://calendly.com/bart-srs"
                                            text={
                                                <span className="txt"><FormattedMessage id="book-demo" /></span>
                                            } />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-12">
                        <div className="banner__content__right">
                            <figure className="banner__right">
                                <img src={Background} alt="" className="box-shadow" />
                            </figure>
                        </div>
                    </div>

                </div>
            </div>

            {openContactModal && (
                <ContactModal
                    modalIsOpen={openContactModal}
                    modalIsOpenHandler={setOpenContactModal}
                    history={history}
                    {...props}
                />
            )}
        </section>

    );
};


export default HomePageHero;