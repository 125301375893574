import React from "react";
import { Layout, } from 'antd';
import { useNavigate, } from 'react-router-dom';
import { Helmet } from "react-helmet-async";

// import { useIntl } from "react-intl";

import { PublicLayout } from '../../components/layouts';
import HomePageHero from './views/hero';
import HomeHeaderBar from '../../components/header/header';
import PageFooter from '../../components/footer/footer';
import ProductList from './views/product.list';
import BestSoftwareView from './views/best.software.view';
import ContactView from './views/contact.view';
import RequestDemoView from './views/request.demo.view';

const { Content } = Layout;

const HomePage = ({
    ...props
}) => {

    const { GApageView } = props;
    GApageView(window.location.pathname);
    const history = useNavigate();


    return (
        <PublicLayout className=" bg-gray-01" {...props}>
            <Helmet>
                <title>{process.env.REACT_APP_PAGE_NAME}</title>
                <meta name="description" content={process.env.REACT_APP_META_DESCRIPTION} />
                <meta name="image" content="/images/share_preview.png" />
                <link rel="canonical" href={`${process.env.REACT_APP_WEBSITE}`} />

                {/* Other SM Meta Tags */}
                <meta property="og:url" content={`${process.env.REACT_APP_WEBSITE}`} />
                <meta property="og:title" content={process.env.REACT_APP_PAGE_NAME} />
                <meta property="og:site_name" content={process.env.REACT_APP_PAGE_NAME} />
                <meta property="og:description" content={process.env.REACT_APP_META_DESCRIPTION} />
                <meta property="og:image" content="/images/share_preview.png" />

                {/* Twitter meta tags */}
                <meta property="twitter:url" content={`${process.env.REACT_APP_WEBSITE}`} />
                <meta name="twitter:title" content={process.env.REACT_APP_PAGE_NAME} />
                <meta name="twitter:description" content={process.env.REACT_APP_META_DESCRIPTION} />
                <meta name="twitter:image" content="/images/share_preview.png" />
            </Helmet>

            <HomeHeaderBar {...props} history={history} />

            <Content>
                <HomePageHero {...props} />
                <ProductList history={history} {...props} />
                <BestSoftwareView {...props} />
                <RequestDemoView {...props} />
                <ContactView {...props} />
            </Content>

            <PageFooter {...props} />

        </PublicLayout>
    );

};

export default HomePage;