import React, { useEffect, useState, } from "react";
import { FormattedMessage, } from "react-intl";
import { Row, Col, Spin, } from 'antd';
import { Link, } from 'react-router-dom';
import ProductCardView from '../../products/product.card';
import * as api from '../../../services/api';

const ProductList = ({
    history,
    ...props

}) => {

    const [products, setProducts] = useState([]);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const { GApageView, } = props; //locale
    GApageView(window.location.pathname);

    useEffect(() => {
        api.get('/public/plan/list').then(response => {
            const data = response.data.data.product_plans;
            setProducts(data, setLoadingProducts(false));
        });
    }, []);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <>
            <section className="product__section sec__pad" id="products"
                style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}>
                <div className="auto_container">
                    <div className="title">
                        <h2><FormattedMessage id="our-products" /></h2>
                    </div>
                    <Row
                        align="middle"
                        justify="space-evenly">
                        <Col sm={22} md={20} lg={16} xl={18}>

                            <div className="title__text">
                                <p><FormattedMessage id="homepage-product-list-title" /></p>
                            </div>

                        </Col>
                    </Row>
                </div>


                <Row
                    align="middle"
                    justify="space-evenly"
                    className="mt-4">

                    {/* <div className="product-infinite grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8"> */}

                    {loadingProducts && (
                        <Col span={24} className="text-center p-4">
                            <Spin size="large" />
                        </Col>
                    )}

                    {!loadingProducts && products.map((item, index) => (
                        <Col sm={24} md={12} lg={12} xl={8} xxl={6} key={index}>
                            <ProductCardView
                                history={history}
                                key={item.plugin_key}
                                data={item}
                                {...props}
                            />
                        </Col>
                    ))}

                    <Col span={24} className="text-center sec__pad">
                        <Link to="/products">
                            <button
                                className="btn-one"
                                onClick={scrollToTop}
                            >
                                <span className="txt"><FormattedMessage id="visit-products" /></span>
                            </button>
                        </Link>
                    </Col>
                </Row>
            </section>


            {/* Plugin section */}
            <section
                style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}
                className="product__section sec__pad" id="marketplace">

                <div className="auto_container">
                    <div className="title">
                        <h2><FormattedMessage id="marketplace" /></h2>
                    </div>
                    <Row
                        align="middle"
                        justify="space-evenly">
                        <Col sm={22} md={20} lg={16} xl={18}>
                            <div className="title__text">
                                <p><FormattedMessage id="homepage-plugin-list-title" /></p>

                                <div className="text-center mb-4">
                                    <Link to="/plugins">
                                        <button className="btn-one"
                                            onClick={scrollToTop}>
                                            <span className="txt"><FormattedMessage id="visit-marketplace" /></span>
                                        </button>
                                    </Link>

                                </div>

                            </div>
                        </Col>
                    </Row>
                </div>

            </section>
        </>
    );
};


export default ProductList;
