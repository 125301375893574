import React, { useState, } from "react";
import ContactModal from '../../../components/contact/contact.modal';
import { FormattedMessage } from "react-intl";
import { PopupButton, } from "react-calendly";

const RequestDemoView = ({
    history,
    ...props

}) => {
    const { GAevent } = props;
    const [openContactModal, setOpenContactModal] = useState(false);

    return (

        <section
            style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}
            className="product__section" id="request_demo">
            <div className="auto_container py-4">
                <div className="title py-4">
                    <h2><FormattedMessage id="request-free-demo" /></h2>
                </div>

                <div className="row justify-content-center py-4">

                    <div className="col-xl-8 col-lg-8 col-md-10 col-sm-12">
                        <div className="title__text text-center">

                            <div className="text-center">
                                <p><FormattedMessage id="request-free-demo-info" /></p>
                            </div>

                            <div className="text-center p-4">
                                <div className="form-group"
                                    onClick={e => { GAevent('CTA', 'request_demo') }}>
                                    <PopupButton
                                        className="btn-one my-2"
                                        rootElement={document.getElementById("root")}
                                        url="https://calendly.com/bart-srs"
                                        text={
                                            <span className="txt"><FormattedMessage id="book-demo" /></span>
                                        } />

                                    {/* <button className="btn-one" onClick={e => { setOpenContactModal(true); GAevent('CTA', 'request_demo') }}>
                                        <span className="txt"><FormattedMessage id="book-demo" /></span>
                                    </button> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {openContactModal && (
                <ContactModal
                    modalIsOpen={openContactModal}
                    modalIsOpenHandler={setOpenContactModal}
                    history={history}
                    modalType="demo"
                    {...props} />
            )}

        </section>

    );
};


export default RequestDemoView;