import React from "react";
import DashboardImage from '../../../assets/images/demo_dashboard.png';
import { CheckCircle } from "@phosphor-icons/react";
import { FormattedMessage, } from "react-intl";

const BestSoftwareView = ({
    history,
    ...props

}) => {


    return (

        <section
            style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}
            className="product__section py-4" id="empty">
            <div className="auto_container py-4">
                <div className="title py-4">
                    <h2><FormattedMessage id="best-software-rental-companies" /></h2>
                </div>

                <div className="row justify-content-center py-4">

                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div className="widget_content">
                            <ul className="links_list">
                                <li><CheckCircle size={24} weight="bold" color="orange" /> <FormattedMessage id="best-sotware-item-a" /></li>
                                <li><CheckCircle size={24} weight="bold" color="orange" /> <FormattedMessage id="best-sotware-item-b" /></li>
                                <li><CheckCircle size={24} weight="bold" color="orange" /> <FormattedMessage id="best-sotware-item-c" /></li>
                                <li><CheckCircle size={24} weight="bold" color="orange" /> <FormattedMessage id="best-sotware-item-d" /></li>
                                <li><CheckCircle size={24} weight="bold" color="orange" /> <FormattedMessage id="best-sotware-item-e" /></li>
                                <li><CheckCircle size={24} weight="bold" color="orange" /> <FormattedMessage id="best-sotware-item-f" /></li>
                                <li><CheckCircle size={24} weight="bold" color="orange" /> <FormattedMessage id="best-sotware-item-g" /></li>
                                <li><CheckCircle size={24} weight="bold" color="orange" /> <FormattedMessage id="best-sotware-item-h" /></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div className="banner__content__right">
                            <figure className="banner__right">
                                <img src={DashboardImage} alt="Smart Rental Solutions dashboard" className="box-shadow" />
                            </figure>
                        </div>
                    </div>

                </div>
            </div>
        </section>

    );
};


export default BestSoftwareView;