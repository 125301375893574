import React from "react";

import { FormattedMessage, } from "react-intl";
// import { Link, } from 'react-router-dom';
import ContactImage from '../../../assets/images/delft.jpg';
import { EnvelopeSimple, MapPin, PhoneCall, } from "@phosphor-icons/react";

const ContactView = ({
    auth,
    history,
    ...props
}) => {

    return (

        <section
            style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}
            className="contact__us__home sec__pad" id="contact">
            <div className="auto_container">
                <div className="title">
                    <h2><FormattedMessage id="visit-contact" /></h2>
                </div>
                <div className="title__text two">
                    <p><FormattedMessage id="visit-contact-info" /></p>
                </div>

                <div className="row">
                    <div className="col-xl-5 col-lg-12 ">
                        <div className="contact__left">
                            <figure className="image">
                                <img src={ContactImage} alt="" />
                            </figure>
                        </div>
                    </div>

                    <div className="col-xl-7 col-lg-12 ">
                        <div className="map__block">
                            <div className="google__map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9044.209851051677!2d4.37083613425794!3d51.98739378430002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5b5fe04fb0b67%3A0x302ff0cb73b663b3!2sSmart%20Rental%20Solutions%20B.V.!5e0!3m2!1snl!2snl!4v1696528675087!5m2!1snl!2snl"
                                    width="100%" height="516"
                                    title="Goole maps"
                                    allowFullScreen="" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                            <div className="contact__info">
                                <ul>
                                    <li>
                                        <div className="icon__left">
                                            <MapPin weight="light" size={45} />
                                        </div>
                                        <div className="text__block">
                                            <p className="text-title"><FormattedMessage id="office-address" /></p>
                                            <p>Rotterdamseweg 183c <br /> 2629HD Delft</p>
                                        </div>
                                    </li>
                                    <li className="mt-4">
                                        <div className="icon__left">
                                            <EnvelopeSimple weight="light" size={45} />
                                        </div>
                                        <div className="text__block">
                                            <p className="text-title"><FormattedMessage id="email-address" /></p>
                                            <p><a href="mailto:info@smartrentalsolutions.nl">info@smartrentalsolutions.nl</a></p>
                                        </div>
                                    </li>
                                    <li className="mt-4">
                                        <div className="icon__left">
                                            <PhoneCall weight="light" size={45} />
                                        </div>
                                        <div className="text__block">
                                            <p className="text-title"><FormattedMessage id="call-us" /></p>
                                            <div className="phone">
                                                <a href="tel:+31882026161" aria-label="Bel ons!">088 - 202 6161 / 06 - 1500 1757</a>
                                                {/* <a href="tell:8888888888">77 7777 7777</a> */}
                                            </div>
                                        </div>
                                    </li>
                                    {/* <li className="mt-4">
                                        <div className="icon__left">
                                            <LinkedinLogo size={45} weight="light" />
                                        </div>
                                        <div className="text__block">
                                            <p className="text-title"><FormattedMessage id="follow-linkedin" /></p>
                                            <p><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/smart-rental-soluitions">Smart Rental Solutions</a></p>
                                        </div>
                                    </li> */}

                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};


export default ContactView;