
import React, { useEffect, useState, } from "react";
import { FormattedMessage } from "react-intl";
import { Link, } from 'react-router-dom';

import { FormatEuro, Truncate, } from '../../components/helpers';
import PlaceHolder from '../../assets/images/placeholder.png';

const ProductCardView = ({
    data,
    history,
    ...props

}) => {

    const [planPrice, setPlanPrice] = useState(0);
    const { locale } = props;

    useEffect(() => {
        calculatePrice(24);
        // eslint-disable-next-line
    }, [data]);

    // Caluclate the discount price
    const calculatePrice = (value) => {
        const discount = data?.plan_discount ? data?.plan_discount?.filter((discount) => { return discount.months === value })?.[0]?.percentage : 0;

        if (discount) {
            setPlanPrice(data.plan_month_price - (data.plan_month_price * (discount / 100)));
        } else {
            setPlanPrice(data.plan_month_price);
        }
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (

        <div className="pointer mb-4 product-box gradient-box flex justify-between flex-col bg-white shadow rounded transition hover:shadow-lg aos-init aos-animate"
            onClick={scrollToTop}
            data-aos="fade-up">

            <Link to={`/product/${data.plan_key}`}>
                <div className="product-top relative bg-white">
                    <div className="product-image relative rounded overflow-hidden mb-8">
                        <img className="w-full sm:h-56 rounded object-cover"
                            src={data.plan_image ? `/images/products/${data.plan_image}` : PlaceHolder} alt="title" />
                    </div>

                    <div className="product-meta absolute left-0 right-0 m-auto bottom-24 w-36 text-white text-center font-medium rounded py-2 px-4 transition-all duration-500 bg-gradient-to-tl from-indigo-500 via-purple-500 to-indigo-500 bg-size-200 bg-pos-0 hover:bg-pos-100"
                        style={{ zIndex: 100 }}
                    // href={`${process.env.REACT_APP_ADMIN}/system/plugin/add/${data.plan_id}`}
                    >
                        <FormattedMessage id="read-more" />
                    </div>

                    <div className="product-content px-6">
                        <h3 className="font-display text-xl text-blueGray-900 font-bold transition hover:text-indigo-500">
                            <span>{data.plan_names[locale || 'en']}</span>
                        </h3>
                        {/* <h5 className="font-display text-sm">{data.plan_name}</h5> */}
                        <div className="flex items-center justify-between mt-4">
                            <Truncate str={data.plan_descriptions[locale || 'en']} length={200} />
                        </div>

                    </div>
                </div>

                <div className="product-bottom bg-white flex items-center flex-wrap justify-between pt-4 px-6 pb-6">
                    <div>
                        <p className="font-body text-sm text-blueGray-600"><FormattedMessage id="price-from" /></p>
                    </div>
                    <div className="text-center">
                        <p className="flex items-center font-body font-bold text-blueGray-900 my-1">
                            <FormatEuro amount={planPrice} />
                        </p>
                    </div>
                </div>
            </Link>
        </div>

    );
};

export default ProductCardView;