import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
import App from './App';

import './assets/styles/plugin.style.css';
import './assets/styles/bootstrap.css';
import './assets/styles/flaticon_xyz.css';
import './assets/styles/global.css';
import './assets/styles/style.css';
import './assets/styles/responsive.css';
import './assets/styles/spinner.style.css';
import './assets/styles/color.css';

const container = document.getElementById('root');
const root = createRoot(container);
// const defaultData = {
//     borderRadius: 3,
//     colorPrimary: '#1ebbd6',
//     colorPrimaryHover: '#118093',
//     hashed: false,
// };

root.render(<React.StrictMode>
    <ConfigProvider
        csp={{ nonce: `${process.env.REACT_APP_GENERATED_NONCE_VALUE}` }}
        // csp={{ nonce: 'YourNonceCode' }}
        theme={{
            token: {
                // colorPrimary: defaultData.colorPrimary,
                // colorPrimaryHover: defaultData.colorPrimaryHover,
                borderRadius: 2,
            },
            // hashed: false,
        }}>
        <App />
    </ConfigProvider>
</React.StrictMode>);
// root.render(<App />);
