import React, { useState, } from "react";
import { Modal, Form, Input, Result, Button, } from 'antd';
import * as api from '../../services/api';

import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { EnvelopeOpen, PaperPlaneTilt, X } from "@phosphor-icons/react";

const ContactModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    modalType,
    history,
    ...props
}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [contactSuccess, setConctactSuccess] = useState(false);
    const { GAevent, } = props;
    const intl = useIntl();
    const { handleSubmit, control, } = useForm(
        { mode: "all" }, // set the field validation mode
    );

    const onSubmit = (data) => {
        setButtonDisabled(true);
        GAevent('CTA', "Contact Modal Success");
        api.post('/mailler/contact', data).then(response => {
            if (response.data.status) {
                setConctactSuccess(true);
            }
        });
    };

    return (

        <Modal
            open={modalIsOpen}
            title={<><EnvelopeOpen size={20} weight="light" /> <FormattedMessage id="contact-us" /></>}
            width={700}
            onCancel={() => modalIsOpenHandler(false)}
            footer={[
                <Button key="1" hidden={contactSuccess} onClick={() => modalIsOpenHandler(false)}><X size={16} className="mr-1" /> <FormattedMessage id="cancel" /></Button >,
                <Button key="2"
                    hidden={contactSuccess}
                    className="btn-success"
                    loading={buttonDisabled}
                    onClick={handleSubmit(onSubmit)}>
                    <PaperPlaneTilt size={16} className="mr-1" /> <FormattedMessage id="send" />
                </Button >,
            ]}>

            {!contactSuccess && (
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="vertical"
                    size="large"
                    className="login-form mt-4">

                    {modalType === "demo" && (
                        <div className="login-content">
                            <p className="text-center h4"><FormattedMessage id="request-free-demo" /></p>
                        </div>
                    )}

                    {/* First & Last name */}
                    <Form.Item
                        label={<strong><FormattedMessage id="contact-person" /></strong>}
                        required
                        style={{ marginBottom: 0 }}>
                        <FormattedMessage id="first-name">
                            {NameField =>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="firstname"
                                    rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Item
                                            help={error && intl.formatMessage({ id: error?.message })}
                                            hasFeedback
                                            validateStatus={error?.message && "error"}
                                            required
                                            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                        // label={<FormattedMessage id="firstname-and-lastname" />}
                                        >
                                            <Input
                                                placeholder={NameField}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        </Form.Item>
                                    )}
                                />}
                        </FormattedMessage>

                        <FormattedMessage id="last-name">
                            {NameField =>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="lastname"
                                    rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Item
                                            help={error && intl.formatMessage({ id: error?.message })}
                                            hasFeedback
                                            validateStatus={error?.message && "error"}
                                            required
                                            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}>
                                            <Input
                                                placeholder={NameField}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        </Form.Item>
                                    )}
                                />}
                        </FormattedMessage>
                    </Form.Item>

                    {/* Mail address */}
                    <FormattedMessage id="emailaddress">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="email"
                                rules={{
                                    required: { value: true, message: "inputfield-FieldIsRequired" },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                        message: "EmailAddressNotValid",
                                    },
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        label={<strong>{NameField}</strong>}
                                        required>
                                        <Input
                                            onChange={onChange}
                                            value={value}
                                            placeholder={NameField}
                                        // prefix={<MailOutlined className="site-form-item-icon" />}
                                        // onChange={(e) => { props.onChange(e) }}
                                        // checked={props.value}
                                        />
                                    </Form.Item>
                                )}
                            />
                        }
                    </FormattedMessage>

                    {/* phone number */}
                    <FormattedMessage id="phone-number">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="phone"
                                rules={{
                                    required: { value: true, message: "inputfield-FieldIsRequired" },
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        label={<strong>{NameField}</strong>}
                                        required>
                                        <Input
                                            onChange={onChange}
                                            value={value}
                                            placeholder={NameField}
                                        // prefix={<MailOutlined className="site-form-item-icon" />}
                                        // onChange={(e) => { props.onChange(e) }}
                                        // checked={props.value}
                                        />
                                    </Form.Item>
                                )}
                            />
                        }
                    </FormattedMessage>

                    {/* Free note field */}
                    <FormattedMessage id="note">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue={modalType === "demo" ? "Hierbij schrijf ik me in voor een gratis demo." : ""}
                                name="comments"
                                rules={{
                                    required: { value: true, message: "inputfield-FieldIsRequired" },
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        label={<strong>{NameField}</strong>}
                                        required>
                                        <Input.TextArea
                                            onChange={onChange}
                                            value={value}
                                            placeholder={NameField}
                                        // prefix={<MailOutlined className="site-form-item-icon" />}
                                        // onChange={(e) => { props.onChange(e) }}
                                        // checked={props.value}
                                        />
                                    </Form.Item>
                                )}
                            />
                        }
                    </FormattedMessage>



                    {/* <FormattedMessage id="company_account">
                    {NameField =>
                        <Controller
                            control={control}
                            defaultValue=""
                            name="company_account"
                            rules={{
                                required: { value: true, message: "inputfield-FieldIsRequired" },
                            }}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { error },
                            }) => (
                                <Form.Item
                                    help={error && intl.formatMessage({ id: error?.message })}
                                    hasFeedback
                                    validateStatus={error?.message && "error"}
                                    label={<strong>{NameField}</strong>}
                                    required
                                >
                                    <Switch
                                        onChange={onChange}
                                        value={value}
                                        placeholder={NameField}
                                        prefix={<MailOutlined className="site-form-item-icon" />}
                                        checked={props.value}
                                    /> <small><FormattedMessage id="company-account-info" /></small>
                                </Form.Item>
                            )}
                        />
                    }
                </FormattedMessage> */}


                </Form>
            )}

            {contactSuccess && (
                <Result
                    status="success"
                    title={<FormattedMessage id="contact-success" />}
                    subTitle={<FormattedMessage id="contact-success-info" />}
                    extra={[
                        <Button onClick={e => modalIsOpenHandler(false)} key="close"><FormattedMessage id="close" /></Button>,
                    ]}
                />
            )}

        </Modal>
    );
};


export default ContactModal;
