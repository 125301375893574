import React, { useState, } from "react";
import { FormattedMessage, } from "react-intl";
import { Dropdown, Space, Menu, Drawer, Tooltip, } from 'antd';
import {
    House, SquaresFour, AddressBook,
    Briefcase, X, CaretDown, Lifebuoy, SignIn, Translate, ReadCvLogo,
} from '@phosphor-icons/react';
import { Link, } from 'react-router-dom';
import MobileMenuIcon from '../../assets/images/mobilemenu.svg';
import SRSLogo from './../../assets/images/Logo_SmartRentalSoltution.png';
import ContactModal from '../../components/contact/contact.modal';

const HomeHeaderBar = ({
    // auth,
    history,
    counterShoppingcart = 0,
    copyCategories = () => { },
    setAuth = () => { },

    ...props
}) => {

    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const { branding_logo, } = props;
    const { locale, setCurrentLanguage } = props;

    // Language settings
    const items = [
        {
            label: <><img className="language-flag-item" alt="Language EN" src='/images/flags/en.png' /> EN</>,
            key: 'en',
            onClick: (e) => { setCurrentLanguage(e.key); }
        },
        {
            label: <><img className="language-flag-item" alt="Language NL" src='/images/flags/nl.png' /> NL</>,
            key: 'nl',
            onClick: (e) => { setCurrentLanguage(e.key); }
        },
        {
            label: <><img className="language-flag-item" alt="Language DE" src="/images/flags/de.png" /> DE</>,
            key: 'de',
            onClick: (e) => { setCurrentLanguage(e.key); }
        },
    ];

    // Menu items
    const menuItems = [
        {
            key: "home",
            icon: <House weight="thin" size={22} />,
            label: <FormattedMessage id="home" />,
            className: "text-heading lh-1 text-capitalize py-2",
            onClick: () => { history('/'); setOpenMenu(false); },
        },
        {
            key: "modules",
            icon: <Briefcase weight="thin" size={22} />,
            label: <Link to="/products"><FormattedMessage id="products" /></Link>,
            className: "text-heading lh-1 text-capitalize py-2",
        },
        {
            key: "plugins",
            icon: <SquaresFour weight="thin" size={22} />,
            label: <Link to="/plugins">Marketplace</Link>,
            className: "text-heading lh-1 text-capitalize py-2",
        },
        {
            key: "contact",
            icon: <AddressBook weight="thin" size={22} />,
            label: <FormattedMessage id="contact" />,
            className: "text-heading lh-1 sidebar-item py-2 text-capitalize",
            onClick: () => { setIsContactModalOpen(true); },
        },
        {
            key: "support",
            icon: <Lifebuoy weight="thin" size={22} />,
            label: <a href="https://smartrentalsolutions.freshdesk.com/support/home" target="_blank" rel="noreferrer"><FormattedMessage id="support" /></a>,
            className: "text-heading lh-1 sidebar-item py-2 text-capitalize",
        },
        {
            key: "vacature",
            icon: <ReadCvLogo weight="thin" size={22} />,
            label: <Link to="/page/werkenbij"><FormattedMessage id="werken-bij" /></Link>,
            className: "text-heading lh-1 sidebar-item py-2 text-capitalize",
        },
    ];

    const mobileMenuItems = [
        { type: 'divider' },
        {
            key: 'login',
            label: <Tooltip title={<FormattedMessage id="user-login" />}>
                <div className="pointer"
                ><Link to={process.env.REACT_APP_ADMIN}>
                        <SignIn size={26} weight="light" /> Login
                    </Link>
                </div>
            </Tooltip>,
        },
        {
            key: 'lang',
            label: <Dropdown
                menu={{ items, }}>
                <div>
                    <Space className="text-uppercase">
                        <Translate size={26} weight="light" className="mr-" />
                        {locale}
                        <CaretDown size={16} className="ml-1" />
                    </Space>
                </div>
            </Dropdown>,
            className: "text-heading lh-1 text-capitalize active py-2",
            // onClick: () => { history("/dashboard") }
        },
    ];

    // const openExternalURL = (url, blank = false) => {
    //     // window.location.href = process.env.RETACT_APP_ADMIN;
    //     // window.open(process.env.REACT_APP_ADMIN, "_blank");
    //     window.open(url, blank ? "_blank" : "_self");
    // };

    return (

        <React.Fragment>
            {/* <!-- main header --> */}
            <header className="main-header">

                {/* <!-- header-lower --> */}
                <div className="header-lower">
                    <div className="header_bottom p_relative">
                        <div className="logo__shape"></div>
                        <div className="auto_container">
                            <div className="outer-box">

                                <div className="logo-box">
                                    <figure className="logo"><a href="/" aria-label="Bezoek onze homepage."><img src={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : SRSLogo} alt="" /></a></figure>
                                </div>

                                <div className="menu-area">

                                    <div className="mobile-nav-toggler"
                                        onClick={e => { setOpenMenu(!openMenu) }}>
                                        <img src={MobileMenuIcon} alt="" />
                                    </div>

                                    <nav className="main-menu navbar-expand-md navbar-light">
                                        <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                            <ul className="navigation clearfix">

                                                {menuItems.map((item, index) => (
                                                    <li key={index}>
                                                        {item.href ? (
                                                            <Link to={item.href}><div>{item.icon} {item.label}</div></Link>
                                                        ) : (
                                                            <div onClick={item.onClick}>{item.icon} {item.label}</div>
                                                        )}
                                                    </li>
                                                ))}

                                            </ul>
                                        </div>
                                    </nav>
                                </div>

                                <div className="header__right">
                                    <div className="icon__list">
                                        <ul>

                                            {/* Login to admin panel */}
                                            <li>
                                                <Tooltip title={<FormattedMessage id="user-login" />}>
                                                    <a href={process.env.REACT_APP_ADMIN} target="_blank" rel="noreferrer">
                                                        <div className="pointer">
                                                            <SignIn size={26} weight="light" />
                                                        </div>
                                                    </a>
                                                </Tooltip>
                                            </li>

                                            {/* Language selection */}
                                            <li>
                                                <Dropdown
                                                    menu={{ items, }}>
                                                    <div>
                                                        <Space className="text-uppercase">
                                                            {locale}
                                                            <CaretDown size={16} className="mr-1" />
                                                        </Space>
                                                    </div>
                                                </Dropdown>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </header>

            {/* <!-- Mobile Menu  --> */}
            <div className="mobile-menu">
                <div className="menu-backdrop"></div>
                <div className="close-btn"><i className="fas fa-times"></i></div>
                <nav className="menu-box">
                    <div className="nav-logo"><a href="/" aria-label="Bezoek onze homepage"><img src={SRSLogo} alt="" title="" /></a></div>
                    <div className="menu-outer">

                        <Drawer
                            title={<figure className="logo" style={{ width: 100 }}>
                                <a href="/" aria-label="Bezoek onze homepage"><img src={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : SRSLogo} alt="" /></a>
                            </figure>}
                            placement="left"
                            closeIcon={false}
                            onClose={e => setOpenMenu(false)}
                            open={openMenu}
                            key="left"
                            width="75%"
                            className="menu-mobile"
                            extra={[
                                <span
                                    key="close"
                                    onClick={e => setOpenMenu(false)}>
                                    <X size={16} className="mr-1" />
                                </span>
                            ]}>

                            <Menu
                                className="mt-4"
                                style={{ width: "100%" }}
                                items={menuItems.concat(mobileMenuItems)}
                            />

                        </Drawer>

                    </div>
                </nav>
            </div>
            {/* <!-- End Mobile Menu --> */}


            {isContactModalOpen && (
                <ContactModal
                    modalIsOpen={isContactModalOpen}
                    modalIsOpenHandler={setIsContactModalOpen}
                    history={history}
                    {...props}
                />
            )}

        </React.Fragment>
    );
};


export default HomeHeaderBar;